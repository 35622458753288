<template>
  <div class="p-2 w-100">
    <div class="questionnaire-container">
      <div class="questionBox">
        <div class="text-center mb-2">
          <h2>Forgot your password?</h2>
          <p>
            Please provide the email address you used to create your account and we will send you a link to reset your password.
          </p>
          <hr class="mhc-divider m-auto" />
        </div>

        <div class="questionContainer">
          <!-- form -->
          <validation-observer ref="forgotPasswordRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="Email Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="btnDisabled"
              >
                SEND EMAIL
              </b-button>
              <div v-if="loading" class="d-flex justify-content-center mt-2">
                <b-spinner variant="primary" label="Loading..." />
              </div>
            </b-form>
          </validation-observer>
        </div>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { BSpinner } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // validation
      required,
      email,
      loading: false,
      btnDisabled: false,
    };
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordRules.validate().then((success) => {
        // If validation is ok
        if (success) {
          this.loading = true;
          this.btnDisabled = true;

          // Submit email
          this.$http.post("/api/forgot-password", {
              email: this.userEmail,
            })
            .then((response) => {
              this.loading = false;
              this.btnDisabled = false;
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Password reset link sent",
                  text: "A password reset link email has been sent to " 
                    + this.userEmail + 
                  ". Follow the instructions in the email to reset your password.",
                  icon: "CheckSquareIcon",
                  variant: "success"
                }
              },
              {
                timeout: 10000,
              });
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.loading = false;
              this.btnDisabled = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Password reset link sent",
                  text: "A password reset link email has been sent to " 
                    + this.userEmail + 
                  ". Follow the instructions in the email to reset your password.",
                  icon: "CheckSquareIcon",
                  variant: "success"
                }
              },
              {
                timeout: 10000,
              });
              this.$router.push({ name: "login" });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
